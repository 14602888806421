import {
    CmsBlockContainer as SourceCmsBlockContainer,
} from 'SourceComponent/CmsBlock/CmsBlock.container';

import CmsBlockQuery from 'Query/CmsBlock.query';
import { CmsBlockQueryOutput } from 'Query/CmsBlock.type';
import { ReactElement } from 'Type/Common.type';
import DataContainer from 'Util/Request/DataContainer';
import CmsBlock from './CmsBlock.component';

/** @namespace AlmusbahblendPwa/Component/CmsBlock/Container */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    // TODO implement logic

    state = {
        cmsContent: {},
    };

    containerProps() {
        const { blockType, children } = this.props;
        const { cmsContent } = this.state;

        const cmsContentByIdentifier = this._getCmsBlock();

        return { cmsBlock: cmsContentByIdentifier || cmsContent, blockType, children };
    }

    _getCmsBlock() {
        const { identifier } = this.props;

        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers: [identifier] })],
            ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    return null;
                }

                this.setState({ cmsContent: items[0] });
                return items[0];
            },
        );
    }

    render() {
        return (
            <CmsBlock
              { ...this.containerProps() }
            />
        );
    }
}

export default CmsBlockContainer;
