import {
    CmsBlockComponent as SourceCmsBlockComponent,
} from 'SourceComponent/CmsBlock/CmsBlock.component';

import './CmsBlock.style';

/** @namespace AlmusbahblendPwa/Component/CmsBlock/Component */
export class CmsBlockComponent extends SourceCmsBlockComponent {
    // TODO implement logic
}

export default CmsBlockComponent;
